@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Allegra";
  src: url("./assets/fonts/Allegra.woff");
}

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica-Light.woff");
}

* {
  color: #00231e;
}

p,
a,
h1,
h2,
h3,
h4 {
  padding: 1rem;
}

a {
  color: #008f79;
}

a:hover {
  text-decoration: underline;
}

.intro {
  background: rgb(244, 216, 227);
  background: linear-gradient(
    20deg,
    rgba(244, 216, 227, 1) 0%,
    rgba(244, 216, 227, 1) 41%,
    rgba(174, 236, 233, 1) 100%
  );
}

.section-transition {
  transition-delay: 100ms, 100ms;
  transition-property: height, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
}
